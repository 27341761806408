.search-panel-accordion {
  &.disabled {
    .MuiAccordionSummary-root {
      .MuiSvgIcon-root {
        opacity: 0.1;
      }
    }
  }

  &.MuiPaper-root.MuiAccordion-root {
    background-color: unset;
    background-image: unset;
    box-shadow: unset;
  }

  .MuiAccordionSummary-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.Mui-expanded {
      border-bottom: 1px solid #42a5f5;
      background: #ffffff26;
    }

    .accordion-title-text {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.15px;
      color: #ffffff;

      .accordion-title-sub-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 17.16px;
        letter-spacing: 0.17px;
        margin-left: 5px;
        opacity: 0.7;
      }

      &.disabled {
        opacity: 0.35;
        .accordion-title-sub-text {
          opacity: 1;
        }
      }
    }

    .MuiSwitch-root {
      margin-right: 5px;
      .Mui-checked {
        color: #42a5f5;
      }
      .MuiIconButton-root {
        color: rgba(255, 255, 255, 0.56);
      }
    }
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 8px 0px;
    gap: 16px;
    background-color: transparent;
  }
}

.accordion-action-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .search-type-toggle {
    &.MuiToggleButtonGroup-root {
      height: 28px;

      .MuiToggleButton-root {
        width: 96px;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.5px;

        &[aria-pressed="true"] {
          background-color: #1976d2;
        }
      }

      button:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      button:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  .clear-modify-btn-group {
    display: flex;
    gap: 8px;

    .modify-add-more-btn {
      background-color: #272727;

      &.button-active,
      &.button-active:hover {
        background-color: rgba(25, 118, 210, 1);
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }
}

.accordion-search-fields-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 560px);
}
