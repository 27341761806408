.search-uwi-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 0px 24px 0px;
  gap: 8px;

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .uwi-upload-buttons-container button h6 {
    color: #90caf9;
    font-size: 0.8rem;
  }
}

// copied from _qbSelect.scss > .select-root
.filter-by-identifier {
  &.MuiFormControl-root {
    margin-bottom: 8px;
  }

  @include qb-field-bottom-border;

  .MuiSelect-nativeInput {
    height: 36px;
  }

  .MuiInputBase-root {
    &.MuiOutlinedInput-root {
      height: 40px;
    }

    .MuiSelect-nativeInput {
      min-width: 60px !important;
    }

    .MuiSelect-select {
      background-color: unset;
    }

    &.MuiInput-root {
      padding-left: 8px;
    }
  }

  .select-value-box {
    display: flex;

    div {
      width: 275px;
    }
    span {
      margin-left: auto;
    }
  }

  .MuiSvgIcon-root {
    margin-right: 2px;
  }
}

.identifier-upload-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;

  .identifier-upload-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
  }

  .upload-btn {
    width: 125px;
    padding: 4px 0px;

    &.disabled {
      cursor: unset;
      pointer-events: none;

      &:hover {
        background-color: unset;
      }
    }

    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
    }
    svg {
      margin-bottom: 2px;
    }
  }

  .chip-root {
    background-color: rgba(25, 118, 210, 1);
  }

  .chip-label {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .upload-progress-container {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-progress {
      font-size: 0.5rem;
    }
  }
}

.search-panel-footer-uwi {
  flex: 0 0 50px;
  padding-top: 10px;
  margin-bottom: auto;
}
