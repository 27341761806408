.search-attributes-list {
  display: flex;
  flex-direction: column;
  padding: 8px 0px 24px 0px;
  gap: 16px;

  & > .numeric-date-attribute {
    border-left: 2px solid #ffffff;
  }

  button.tgsccl-button.modify-add-more-btn {
    color: rgba(0, 0, 0, 0.87);
    .modify-add-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-align: left;
    }
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}
