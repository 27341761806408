@import "./partials/variables";
@import "./partials/mixins";
@import "./partials/fonts";

@import "./partials/header";
@import "./partials/map";
@import "./partials/maptools";
@import "./partials/footer";

@import "./partials/reset";
@import "./partials/icons";
@import "./partials/loader";
@import "./partials/scrollbars";

@import "./components/actionControl";
@import "./components/buttonWithPrompt";
@import "./components/messageBar";
@import "./components/wellCard";
@import "./components/customCheckbox";
@import "./panels/panel";
@import "./panels/savedSearchPanel/savedSearchPanel";
@import "./panels/searchPanel/common/expectedWellCount";
@import "./panels/searchPanel/common/tabPanel";
@import "./panels/searchPanel/attributes/searchAttributesList";
@import "./panels/searchPanel/attributes/polygonSearch";
@import "./panels/searchPanel/attributes/qbAttributes";
@import "./panels/searchPanel/attributes/qbAutocomplete";
@import "./panels/searchPanel/attributes/qbSelect";
@import "./panels/searchPanel/attributes/qbEditor";
@import "./panels/searchPanel/uwi/uwiTabPanel";
@import "./panels/searchPanel/searchPanel";
@import "./panels/searchPanel/searchPanelAccordion";
@import "./panels//dashboardChartsPanel/dashboardCharts.scss";
@import "./panels/wellPanel/WellPanel.scss";
@import "./panels/wellPanel/WellPanelAccordion.scss";
@import "./panels/wellPanel/WellPanelSectionAccordion.scss";
@import "./panels/dstPanel/DstPanel.scss";
@import "./panels/wellPanel/PressureDataSection.scss";
@import "./panels/sidebar";
@import "./datagrid/grid";
@import "./datagrid/gridFilter";
@import "./datagrid/tabbedGrid.scss";
@import "./components/accordion";
@import "./components/chip";
@import "./components/tabs";
@import "./components/loadingBackDrop";
@import "./components/buttonUpload";
@import "./components/tooltip";
@import "./components/select";
@import "./components/divider";
@import "./components//outlinedInputField";
@import "./components/form";
@import "./layout/appLayout";
@import "./export-card/export-card";
@import "./export-menu/export-menu-dialog";
@import "./export-menu/export-r360";
@import "./export-menu/export-select-item";
@import "./export-menu/export-alert.scss";
@import "./export-menu/export-map.scss";
@import "./mapSettingsAccordions/mapSettings.scss";
@import "./modularity/sagaModularWindow";
@import "./charts/plotChartArea";
@import "./charts/chartConfig";
@import "./mapOverlays/mapOverlay";
@import "./mapOverlays/layerInfoOverlay.scss";
@import "./components/wellItemsAccordion";
@import "./components/customMenuSelection";
@import "./components/dataCurrency";
@import "./components/releaseNotes";
@import "./global";

@import "./analysis-toggle/analysis-list-item.scss";
@import "./analysis-toggle/analysis-menu-item.scss";
@import "./analysis-toggle/analysis-menu.scss";
@import "./analysis-toggle/analysis-title-header.scss";
@import "./analysis-toggle/analysis-button.scss";
@import "./analysis-toggle/asset-economics.scss";
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

/* @import './partials/media'; */
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Medium";
  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@import "utilities";
@import "materialui-tweaks";
@import "modal";
@import "react_date_picker_tweaks";
