.tabbed-grid {
  height: 100%;

  .MuiTabs-root {
    background-color: #272727;
    padding: 1px 1px 1px 1px;
    min-height: 40px;

    .MuiTabs-flexContainer {
      height: 40px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 40px;
      right: 0;
      bottom: 0;
      background: #ffffff;
      z-index: 0;
      height: 1px;
    }

    .MuiTab-root {
      max-width: 250px;
      min-height: 40px;

      .custom-tab-label {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          margin-right: 6px;
        }

        .tab-label {
          font-size: 13px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.4px;
          margin-right: 2px;
          color: #ffffff;
        }

        .tab-count {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.17px;
          color: #ffffffb2;
        }
      }

      &[aria-selected="true"] {
        background-color: #ffffff26;

        .custom-tab-label .tab-label {
          color: #42a5f5;
        }

        .tab-count {
          color: #ffffffb2;
        }
      }
    }

    .MuiTabs-indicator {
      background: #42a5f5;
      height: 1px;
    }
  }
}

.data-grid-tab-panel {
  height: 100%;
}
