// LoadingBackDrop
.loading-back-drop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 1000000;
  background-color: #fff;
  color: #fff;
  opacity: 1;

  &.loader {
    background-color: transparent;
  }

  &.cash-flow-chart,
  &.plot-chart-area {
    height: 100%;
    width: 100%;

    &.full-screen-chart {
      &.loader {
        position: relative;
      }
    }
  }

  &.identifier-textbox {
    width: 93%;
    height: 100%;
    min-height: 273px;
    max-height: 515px;
    align-items: unset;

    &.loader {
      padding-top: 116.5px;
    }
  }
}
