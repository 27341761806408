.qb-attribute {
  min-height: 40px;
  display: flex;
  flex-shrink: 0;

  & > * {
    padding: 0px;
    border-left: 2px;
    flex-grow: 1;
  }
}

.numeric-date-attribute {
  display: flex;
  flex-direction: column;
  gap: 16px !important;

  &.has-optype-selected {
    border-left: 2px solid #ffffff;
  }

  .select-operation-type {
    @include qb-field-bottom-border;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    .MuiSelect-select {
      display: flex;
      align-items: center;

      &:focus {
        background-color: unset;
      }

      &.MuiSelect-outlined.MuiInputBase-input {
        height: 40px;
      }
    }

    .MuiInputLabel-formControl {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;

      &[data-shrink="false"] {
        top: -7px !important;
        color: #ffffff;
      }
    }
    .MuiSvgIcon-root {
      color: #ffffff;
      margin-right: 2px;
    }
  }

  .custom-textfield {
    @include qb-field-bottom-border;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    .MuiFormLabel-root {
      &[data-shrink="false"] {
        top: -7px;
        color: #ffffff;
      }
    }

    .MuiInputBase-input {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      padding: 10px 8px 10px 14px;
    }

    .MuiIconButton-root {
      margin-bottom: 15px;
      margin-right: 0px;

      .MuiSvgIcon-root {
        fill: #9c9c9c;
      }
    }

    // Removing number input arrows
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .row-fields {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .is-between-fields {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      &.numeric {
        flex-direction: row;
        align-items: center;
        gap: 8px;

        &.custom-textfield {
          flex-shrink: 1;
        }
      }

      .MuiTypography-root {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .helper-text {
      visibility: hidden;

      .MuiTypography-root {
        font-size: 12px;
        margin-left: 1px;
        color: #f44336;
      }

      &.show {
        visibility: visible;
      }
    }
  }
}
