.search-panel {
  // specified width in figma. (changing this would require a change in other components too)
  // width: 430px;

  .drawer-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .search-panel-accordion-section {
      margin-top: 20px;
      margin-bottom: 16px;
      flex-grow: 1;
    }

    .tabs-container {
      padding: 0px 20px;

      .MuiDivider-root {
        position: relative;
        bottom: 1px;
        border-color: rgba(255, 255, 255, 0.42);
      }
    }

    .nav-tabs {
      height: 48px;
      box-shadow: none;

      .MuiButtonBase-root {
        padding-top: 0px;
      }

      .MuiTab-root {
        color: rgba(255, 255, 255);
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-align: left;

        &.Mui-selected {
          color: #42a5f5;
        }

        svg {
          margin-right: 5px;
          margin-bottom: 2px;
        }
      }

      .MuiTabs-indicator {
        height: 1px;
        background-color: #42a5f5;
      }
    }
  }

  .sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding-top: 16px;
    position: relative;

    .search-panel-header-icon {
      position: absolute;
      right: 10px;
      top: 7px;

      & button {
        padding: 4px;
        color: rgba(255, 255, 255, 0.7);

        &.share-enabled {
          background-color: rgba(39, 39, 39, 255);
          border-radius: 50%;

          &:hover {
            background-color: #39556e;
          }
        }

        & svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .header-name {
    font-size: 1rem;
    word-wrap: break-word;
    min-width: 50%;
  }

  .action-button-container {
    display: flex;
    align-items: center;
    gap: 18px;
    padding-right: 20px;

    button.tgsccl-button.disabled {
      cursor: pointer;
      pointer-events: none;

      &:hover {
        background: unset;
      }
    }

    button.tgsccl-button.primary.disabled {
      svg {
        path {
          fill: rgba(0, 0, 0, 0.87);
        }
      }
    }

    button.MuiButton-root.save-loading-btn {
      width: 106px;
      height: 30px;
      border-radius: 15px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }

      .MuiButton-startIcon {
        margin-right: 7px;
      }
      .MuiButton-endIcon {
        margin-left: 4px;
        padding-bottom: 2px;
      }
      text {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.search-panel-footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 50px;
  padding-top: 10px;
  margin-top: 16px;
  margin-bottom: 22px;
  background: #2e2e2e;

  & > * {
    margin-bottom: 16px !important;
    padding: 0px 10px 0px 8px;
    border-left: 2px;
  }
}

.share-search-menu-container {
  z-index: 1600 !important;

  &.right-placement .MuiPaper-root {
    margin-left: 9px;
  }

  & .MuiPaper-root {
    background-color: #121212;
    width: 132px;
    background-image: none;
    border-radius: 4px;
    margin-top: 5px;

    & ul {
      padding-top: 0;
      padding-bottom: 0;

      & li {
        height: 34px;
        font-size: 14px !important;
        position: relative;

        & span {
          position: absolute;
          right: 15px;
        }
      }
    }
  }
}

.share-link-alert-container {
  .MuiPaper-root {
    width: 320px;
  }

  h2 {
    color: #ffffff;
    font-size: 16px;
  }

  .MuiDialogContent-root {
    font-size: 16px;
    color: #ffffffb2;
    margin-top: 10px;
    padding: 30px 25px;
  }

  .alert-action {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    button {
      border-radius: 15px;
    }
  }
}
