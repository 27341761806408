.autocomplete-root {
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 60px !important;
  }
}

.autocomplete-popper {
  &.MuiAutocomplete-popper {
    z-index: 1150;
  }
}

.autocomplete-textfield-root {
  @include qb-field-bottom-border;

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    padding-left: 11px;
    gap: 4px;
  }

  .MuiAutocomplete-input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    padding-left: 0px;
  }

  .MuiAutocomplete-endAdornment {
    .MuiIconButton-root {
      .MuiSvgIcon-root {
        color: #ffffff;
      }

      &:hover {
        background-color: unset;
      }
    }
  }

  .MuiFormLabel-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;

    &[data-shrink="false"] {
      color: #ffffff;
    }
  }
}
.autocomplete-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 0.67rem;

  .option-label {
    flex-grow: 1;
    margin-right: 4px;
  }

  .option-count {
    align-self: flex-end;
    color: rgba(255, 255, 255, 0.7);
  }
}

// override mui autocomplete popper font
.MuiAutocomplete-popper.MuiPopper-root {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAutocomplete-paper {
    font-size: 0.67rem;
  }
}

.toggle-sort-button {
  width: 100%;
  height: 45px;
  justify-content: start !important;
  text-transform: none !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding-left: 15px !important;
}
